const effect = document.querySelector("#click-effect")

document.addEventListener(
  "pointerdown",
  (event) => {
    const followCursor = (event) => {
      const {clientX: toX, clientY: toY} = event
      const sliding = {
        left: [`${toX}px`, `${toX}px`],
        top: [`${toY}px`, `${toY}px`],
      }
      const timing = {
        duration: 0,
        fill: "forwards",
      }
      effect.animate(sliding, timing)
    }
    document.addEventListener("pointermove", followCursor)
    const playPopEffect = (_) => {
      const popping = {
        height: ["16px", "32px"],
        width: ["16px", "32px"],
        opacity: ["20%", "0"],
        borderWidth: ["8px", "0"],
      }
      const timing = {
        duration: 400,
        fill: "forwards",
      }
      effect.animate(popping, timing)
      document.removeEventListener("pointermove", followCursor)
      document.removeEventListener("pointerup", playPopEffect)
      document.removeEventListener("pointercancel", playPopEffect)
    }
    document.addEventListener("pointerup", playPopEffect)
    document.addEventListener("pointercancel", playPopEffect)

    const {left: fromX, top: fromY} = effect.getBoundingClientRect()
    const {clientX: toX, clientY: toY} = event
    const sliding = {
      left: [`${fromX}px`, `${toX}px`, `${toX}px`],
      top: [`${fromY}px`, `${toY}px`, `${toY}px`],
      height: ["0", "18px", "16px"],
      width: ["0", "18px", "16px"],
      opacity: ["0", "20%", "20%"],
      borderWidth: ["0", "9px", "8px"],
      offset: [0, 0.8],
    }
    const timing = {
      duration: 240,
      fill: "forwards",
    }
    effect.animate(sliding, timing)
  }
)